<!-- Componente Imagen -->

<template>
  <div class="uiImg">
    <div>
      <!-- Imagen -->
      <v-img v-bind="$attrs" :aspect-ratio="3/4" :src="ct[2] ? ct[2] : require('@/assets/img/noimg.jpg')"></v-img>

      <!-- File Input -->
      <input type="file" accept="image/*" id="foto" style="display:none" @change="onfileChanged">

      <!-- Botones -->
      <div class="conflex" style="justify-content:space-between" v-if="disabled">
        <v-btn icon @click="changeImg()" style="margin-top:5px" title="Cambiar Imagen">
          <v-icon> {{ "mdi-image-edit" }} </v-icon>
        </v-btn>

        <v-btn icon @click="deleteImg()" style="margin-top:5px" title="Borrar Imagen">
          <v-icon> {{ "mdi-image-remove" }} </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>



<script>

export default {
    props: {
      ct: { type: Array, default:null },
      disabled: { type:Boolean, default:true }
    },


    data() {
      return {
        files:null,
      };
    },


    methods: {

      // elimina imagen
      deleteImg() {
        this.$root.$alert
          .open(" ¿Está seguro que desea eliminar la imagen?", "confirm")
          .then(r => { if (r) this.deleteImgFin(); }
        );
      },

      // elimina imagen del control
      // emite evento indicando que la imagen ha cambiado
      deleteImgFin() {
        this.$set(this.ct, 2, "");
        this.$set(this.ct[4].comp, 'files', -1);
        this.$emit('change');
      },


      // cambia imagen
      changeImg() {
        this.$root.$alert
          .open(" ¿Está seguro que desea cambiar la imagen?", "confirm")
          .then(r => { if (r) this.changeImgFin(); }
        );
      },

      // lanza el objeto file input
      changeImgFin() {
        const foto= document.getElementById('foto');
        foto.click();
      },


      // guarda la imagen en el control
      // emite evento indicando que la imagen ha cambiado
      async saveImage() {
        this.$set(this.ct[4].comp, 'files', this.files);

        // creo un nuevo FileReader para leer la imagen y convertirla en formato base64
        var reader = new FileReader();

        // leo la imagen como base64
        reader.onload = (e) => {
          this.$set(this.ct, 2, e.target.result);
        }

        // leo imagen como data url
        reader.readAsDataURL(this.files);
        this.$emit('change');
      },


      // salta cuando la imagen de file input cambia
      // guarda la imagen seleccionada en 'files'
      onfileChanged(e) {
        console.log(e);
        this.files= e.target.files[0];
        this.saveImage();
      }

    },

  };
</script>

